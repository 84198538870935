import { axiosGetData } from "../axios/AxiosBasicImpl";
import { PredefineVariableEntity } from "domain/entity/EmailTemplate/PredefineVariableEntity";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { PredefineVariableRepository } from "./PredefineVariableRepo";

/**
 * For template
 * @returns 
 */
export const PredefineVariableRepoImpl = (): PredefineVariableRepository => {
    const url = '/v1/predefineVariable';

    const getAllPredefineVariables = async() : Promise<PredefineVariableEntity[]> => {
        return axiosGetData(referenceAxiosInstance, url, []).then(res => {
            return res.data;
        }).catch(err => {
            return [];
        });
    }

    

    return {
        getAllPredefineVariables: getAllPredefineVariables,
    }
}