
export interface EmailTemplateEntity {
    id:number,
    customerCode: string|null,
    subject: string|null,
    content: string|null,
    activeInd: "Y"|"N",
    variable: string|null,

    [key:string]: string | number | null | undefined
}

export const EMPTY_EMAIL_TEMPLATE_ENTITY:EmailTemplateEntity = {
    id: 0,
    customerCode: "",
    subject: "",
    content: "",
    activeInd: "Y",
    variable: null
}

