
import { EmailTemplateEntity, EMPTY_EMAIL_TEMPLATE_ENTITY } from "domain/entity/EmailTemplate/EmailTemplateEntity";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

export interface EmailTemplateDropdownOptions {
    customerCodeDropdownOptions: DropdownProps[],
    variableDropdownOptions: DropdownProps[],
}
export interface MasterChangeState extends BaseViewChangeSate {
    editingEntity: EmailTemplateEntity,
}

export interface EmailTemplateMaintenanceModel {
    isLoading: boolean,
    tableData: EmailTemplateEntity[],
    currentSelectedRow: EmailTemplateEntity,
    selectedRows: EmailTemplateEntity[],
    dynamicOptions: EmailTemplateDropdownOptions,
    masterState: MasterChangeState,
    isBackMaster: boolean,
}

export const EMPTY_MASTER_EMAIL_TEMPLATEANY_MODEL: EmailTemplateMaintenanceModel = {
    isLoading: false,
    tableData: [],
    currentSelectedRow: { ...EMPTY_EMAIL_TEMPLATE_ENTITY },
    selectedRows: [],
    dynamicOptions: {
        customerCodeDropdownOptions: [],
        variableDropdownOptions: []
    },
    masterState: {
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        },
        editingEntity: EMPTY_EMAIL_TEMPLATE_ENTITY,
    },
    isBackMaster: false,
}