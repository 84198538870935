import { EMPTY_MASTER_EMAIL_TEMPLATEANY_MODEL } from "presentation/model/EmailTemplate/EmailTemplateMaintenanceModel";
import { useState } from "react";
import { createContainer } from "react-tracked";

const {
    Provider: EmailTemplateMaintenanceProvider,
    useTracked: useEmailTemplateMaintenanceTracked
} = createContainer(() => useState(EMPTY_MASTER_EMAIL_TEMPLATEANY_MODEL), {concurrentMode: true});
export { EmailTemplateMaintenanceProvider, useEmailTemplateMaintenanceTracked };

