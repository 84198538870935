import { CustomerRepoImpl } from "domain/repository/Company/CustomerRepoImpl";
import { EmailTemplateRepoImpl } from "domain/repository/EmailTemplate/EmailTemplateRepoImpl";
import { PredefineVariableRepoImpl } from "domain/repository/EmailTemplate/PredefineVariableRepoImpl";
import { useEmailTemplateMaintenanceTracked } from "presentation/store/EmailTemplate/EmailTemplateMaintenanceProvider";
import { EmailTemplateMaintenanceVM } from "presentation/viewModel/EmailTemplate/EmailTemplateMaintenanceVM";
import { useMemo } from "react";

export const useEmailTemplateMaintenanceVM = () => {
    const [, setEmailTemplateMaintenanceState] = useEmailTemplateMaintenanceTracked();
    const emailTemplateMainVM = useMemo(() =>
        EmailTemplateMaintenanceVM({
            dispatch: [setEmailTemplateMaintenanceState],
            repo: EmailTemplateRepoImpl(),
            customerRepo: CustomerRepoImpl(),
            predefineVariableRepo: PredefineVariableRepoImpl(),
        }), [setEmailTemplateMaintenanceState])

    return emailTemplateMainVM
}

