import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { EmailTemplateMaintenanceProvider } from "presentation/store/EmailTemplate/EmailTemplateMaintenanceProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import { EmailTemplateMasterView } from "presentation/view/section/EmailTemplate/EmailTemplateMasterView";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const EmailTemplateMaintenanceCont = () => {
    return <ANAInfoWrapper permission={Permission.EMAIL_TEMPLATE_MAINTENANCE}>
        <MessageBarWrapper>
            <EmailTemplateMaintenanceProvider>
                <GridStyles/>
                <EmailTemplateMasterView/>
            </EmailTemplateMaintenanceProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default EmailTemplateMaintenanceCont;