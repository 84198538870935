export const emailTemplateMaintenanceRequiredFieldList: string[] = [
    'emailTemplate', 
];

export const EmailTemplateMaintenanceConstant = {    
    CATEGORY: "Invoice",
    TITLE: "Email Template",
    EMAIL_TEMPLATE: "Email Template",
    CUST_CODE: 'Customer Code',
    SUBJECT: 'Subject',
    CONTENT: 'Content',
    VARIABLE: 'Variable',
    ACTIVE_IND: 'Active Ind.',
    TO_SUBJECT: "to Subject",
    TO_CONTENT: "to Content",
}
