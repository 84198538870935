import * as yup from 'yup';


export const commonEmailTemplateMaintenanceValidation: { [x: string]: any; } = {
  subject: yup.string().required("Missing"),
  content: yup.string().required("Missing"),
};

export const createEmailTemplateMaintenanceValidation: { [x: string]: any; } = {
  ...commonEmailTemplateMaintenanceValidation,
};

export const createEmailTemplateMaintenanceValidationSchema = yup.object().shape(createEmailTemplateMaintenanceValidation);

